
export const TransportationMethodOtherIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 17V4.61803C1 4.23926 1.214 3.893 1.55279 3.72361L6.55279 1.22361C6.83431 1.08284 7.16569 1.08284 7.44721 1.22361L12.6295 3.81476C12.8667 3.93337 13.1414 3.95288 13.393 3.86901L17.6838 2.43874C18.3313 2.2229 19 2.70487 19 3.38743V15.8333C19 16.237 18.7573 16.6011 18.3846 16.7564L13.4225 18.824C13.1542 18.9357 12.8506 18.9253 12.5906 18.7953L7.40936 16.2047C7.14942 16.0747 6.8458 16.0643 6.57753 16.176L2.38462 17.9231C1.72593 18.1975 1 17.7136 1 17Z" stroke="currentColor" strokeWidth="1.3"/>
    <path d="M4 10H6" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round"/>
    <path d="M9 10H11" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round"/>
    <path d="M14 9L16 11" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round"/>
    <path d="M16 9L14 11" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round"/>
  </svg>
);

export const BusIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_33120_100620)">
      <path d="M4.6664 13.9718C4.6664 14.3417 4.36792 14.6416 3.99972 14.6416C3.63153 14.6416 3.33305 14.3417 3.33305 13.9718C3.33305 13.6019 3.63153 13.302 3.99972 13.302C4.36792 13.302 4.6664 13.6019 4.6664 13.9718Z" fill="currentColor"/>
      <path d="M14.0003 15.7579H6.00034V14.4183H14.0003V15.7579Z" fill="currentColor"/>
      <path d="M16.6671 13.9718C16.6671 14.3417 16.3686 14.6416 16.0004 14.6416C15.6322 14.6416 15.3337 14.3417 15.3337 13.9718C15.3337 13.6019 15.6322 13.302 16.0004 13.302C16.3686 13.302 16.6671 13.6019 16.6671 13.9718Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4348 10.0006C19.3303 10.2001 20 11.0026 20 11.9624V17.3208C20 18.4306 19.1046 19.3302 18 19.3302H16.7879V18.4371H3.21213V19.3302H2C0.895428 19.3302 0 18.4306 0 17.3208V11.9624C0 11.0034 0.668656 10.2013 1.56311 10.0011C7.5 10.0027 13.1795 9.99852 18.4348 10.0006ZM18.6667 17.0975V11.9624C18.6667 11.5925 18.3682 11.2926 18 11.2926H2C1.63181 11.2926 1.33333 11.5925 1.33333 11.9624V17.0975H18.6667Z" fill="currentColor"/>
      <path d="M20 2.96238C20 2.00264 19.3303 1.20005 18.4348 1.0006C15.7282 0.999527 12.9091 1.00011 10.0003 1.0007C7.26115 1.00127 4.44241 1.00184 1.56311 1.00108C0.668656 1.20132 0 2.00338 0 2.96238V12H1.33333V2.96238C1.33333 2.59247 1.63181 2.29258 2 2.29258H18C18.3682 2.29258 18.6667 2.59247 18.6667 2.96238V12H20V2.96238Z" fill="currentColor"/>
      <path d="M10 4L10 10" stroke="currentColor" strokeWidth="1.3"/>
      <path d="M1 4L19 4" stroke="currentColor" strokeWidth="1.3"/>
    </g>
    <defs>
      <clipPath id="clip0_33120_100620">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const BoatIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 13.8625L19 9.90479L15 9.02519M15 9.02519L10 7.92595M15 9.02519L16 3.96826H12M10 7.92595V12.8731M10 7.92595L5 9.02519M5 9.02519L1 9.90479L3 13.8625M5 9.02519L4 3.96826H8M8 3.96826V1H12V3.96826M8 3.96826H12M1 17.8202L2.245 17.3274C2.63207 17.1743 3.05099 17.1166 3.46562 17.1593C3.88026 17.2021 4.27815 17.344 4.625 17.5728C5.14384 17.9151 5.77077 18.0591 6.38895 17.9779C7.00713 17.8967 7.57438 17.5959 7.985 17.1315L8.02 17.0919C8.26685 16.8126 8.57124 16.5887 8.91274 16.4353C9.25424 16.2819 9.62495 16.2025 10 16.2025C10.375 16.2025 10.7458 16.2819 11.0873 16.4353C11.4288 16.5887 11.7331 16.8126 11.98 17.0919L12.016 17.1315C12.4266 17.5959 12.9939 17.8967 13.6121 17.9779C14.2302 18.0591 14.8572 17.9151 15.376 17.5728C15.7228 17.344 16.1207 17.2021 16.5354 17.1593C16.95 17.1166 17.3689 17.1743 17.756 17.3274L19 17.8202" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const TrainIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.13536 16.1461L2.54518 19.7811C2.26106 20.0061 1.83971 19.9685 1.60407 19.6973C1.36843 19.426 1.40773 19.0237 1.69185 18.7987L6.28203 15.1638L7.13536 16.1461Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.13536 16.1461L2.54518 19.7811C2.26106 20.0061 1.83971 19.9685 1.60407 19.6973C1.36843 19.426 1.40773 19.0237 1.69185 18.7987L6.28203 15.1638L7.13536 16.1461Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8379 18.9453H3.16247V17.669H16.8379V18.9453Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.8646 16.1461L17.4548 19.7811C17.7389 20.0061 18.1603 19.9685 18.3959 19.6973C18.6316 19.426 18.5923 19.0237 18.3082 18.7987L13.718 15.1638L12.8646 16.1461Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.19607 5.83877V9.1767H13.805V5.83877H6.19607ZM5.8876 4.5625C5.31973 4.5625 4.85938 5.00204 4.85938 5.54424V9.47122C4.85938 10.0134 5.31973 10.453 5.8876 10.453H14.1134C14.6813 10.453 15.1416 10.0134 15.1416 9.47122V5.54424C15.1416 5.00204 14.6813 4.5625 14.1134 4.5625H5.8876Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.99962 9.81641C10.3687 9.81641 10.668 10.1021 10.668 10.4545V15.756C10.668 16.1084 10.3687 16.3941 9.99962 16.3941C9.6305 16.3941 9.33127 16.1084 9.33127 15.756V10.4545C9.33127 10.1021 9.6305 9.81641 9.99962 9.81641Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.94318 1.27627C7.17655 1.27627 6.55507 1.86965 6.55507 2.60162V3.24432H3.83027C3.06364 3.24432 2.44216 3.83771 2.44216 4.56968V13.2397C2.44216 13.8744 2.91337 14.42 3.56591 14.5408L9.73527 15.6836C9.90988 15.7159 10.0894 15.7159 10.264 15.6836L16.4334 14.5408C17.0859 14.42 17.5571 13.8744 17.5571 13.2397V4.56968C17.5571 3.8377 16.9356 3.24432 16.169 3.24432H13.4442V2.60162C13.4442 1.86965 12.8227 1.27627 12.0561 1.27627H7.94318ZM5.29974 1.96805C5.59605 0.837424 6.66719 0 7.94318 0H12.0561C13.3321 0 14.4032 0.837424 14.6995 1.96805H16.169C17.6739 1.96805 18.8938 3.13284 18.8938 4.56968V13.2397C18.8938 14.4855 17.9688 15.5565 16.6879 15.7937L10.5186 16.9365C10.1758 17 9.82346 17 9.4807 16.9365L3.31134 15.7937C2.03044 15.5565 1.10547 14.4855 1.10547 13.2397V4.56968C1.10547 3.13284 2.3254 1.96805 3.83027 1.96805H5.29974Z" fill="currentColor"/>
  </svg>
);

export const InRoomIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.00039 8.35156C4.35938 8.35156 4.65039 8.64258 4.65039 9.00156V16.0016C4.65039 16.3605 4.35938 16.6516 4.00039 16.6516C3.64141 16.6516 3.35039 16.3605 3.35039 16.0016V9.00156C3.35039 8.64258 3.64141 8.35156 4.00039 8.35156Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.00039 8.35156C8.35938 8.35156 8.65039 8.64258 8.65039 9.00156V16.0016C8.65039 16.3605 8.35938 16.6516 8.00039 16.6516C7.64141 16.6516 7.35039 16.3605 7.35039 16.0016V9.00156C7.35039 8.64258 7.64141 8.35156 8.00039 8.35156Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0004 8.35156C12.3594 8.35156 12.6504 8.64258 12.6504 9.00156V16.0016C12.6504 16.3605 12.3594 16.6516 12.0004 16.6516C11.6414 16.6516 11.3504 16.3605 11.3504 16.0016V9.00156C11.3504 8.64258 11.6414 8.35156 12.0004 8.35156Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0004 8.35156C16.3594 8.35156 16.6504 8.64258 16.6504 9.00156V16.0016C16.6504 16.3605 16.3594 16.6516 16.0004 16.6516C15.6414 16.6516 15.3504 16.3605 15.3504 16.0016V9.00156C15.3504 8.64258 15.6414 8.35156 16.0004 8.35156Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0006 1.48789L2.8838 5.45H17.1173L10.0006 1.48789ZM10.487 0.27081C10.1845 0.102438 9.81655 0.102438 9.51412 0.27081L1.24177 4.87628C0.340627 5.37798 0.696811 6.75 1.7282 6.75H18.2729C19.3043 6.75 19.6605 5.37798 18.7593 4.87628L10.487 0.27081Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.34961 19.0016C1.34961 18.6426 1.64062 18.3516 1.99961 18.3516H17.9996C18.3586 18.3516 18.6496 18.6426 18.6496 19.0016C18.6496 19.3605 18.3586 19.6516 17.9996 19.6516H1.99961C1.64062 19.6516 1.34961 19.3605 1.34961 19.0016Z" fill="currentColor"/>
  </svg>
);

export const SubBoardsIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.96137 6.25546C5.18398 8.75239 3.1911 11.7964 1.99981 13.983C1.89616 14.1733 1.91784 14.5208 2.20711 14.804L4.96809 17.5069C5.25733 17.79 5.60535 17.8044 5.79352 17.6967C7.95501 16.4595 10.9581 14.4043 13.3974 11.5764C15.6541 8.96019 17.4041 5.71256 17.5984 1.85212C13.7424 2.12828 10.5309 3.94541 7.96137 6.25546ZM18.8192 0.604994C18.7326 0.520175 18.5218 0.516033 18.5218 0.516033C13.8366 0.545171 10.0216 2.65514 7.09223 5.28871C4.16654 7.91897 2.09008 11.1001 0.85824 13.3611C0.422211 14.1614 0.679705 15.128 1.2977 15.733L4.05869 18.4358C4.67672 19.0408 5.64847 19.2776 6.43932 18.8249C8.67382 17.5459 11.8122 15.4045 14.3818 12.4255C16.9546 9.44279 18.9851 5.58557 18.9145 0.900548C18.9145 0.900548 18.9059 0.689824 18.8192 0.604994Z" fill="currentColor"/>
    <path d="M2.00039 12.9193C2.33198 12.6676 3.23917 12.3375 3.65001 12.2671C4.06085 12.1967 4.48226 12.2263 4.87788 12.3534C5.46969 12.5435 6.11224 12.5134 6.68577 12.2689C7.2593 12.0244 7.7247 11.582 7.99522 11.0243L8.01828 10.9767C8.18086 10.6413 8.41377 10.3437 8.70139 10.1041C8.98902 9.86442 9.3247 9.68824 9.68592 9.58732C10.0471 9.4864 10.4255 9.46309 10.7957 9.51895C11.1659 9.5748 11.5193 9.70854 11.8322 9.91118L11.8775 9.93962C12.398 10.2764 13.0252 10.4134 13.6425 10.3253C14.2597 10.2372 14.8248 9.9298 15.2324 9.46051C15.5048 9.1468 16.0588 8.3218 16.4466 8.16906" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const IndividualIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.562 10.0739C13.5469 10.5388 14.3867 11.263 14.9913 12.1688C15.5959 13.0747 15.9425 14.128 15.994 15.2159C16.0009 15.3155 15.9878 15.4155 15.9555 15.51C15.9232 15.6045 15.8723 15.6916 15.8059 15.7662C15.7394 15.8408 15.6588 15.9014 15.5686 15.9444C15.4784 15.9874 15.3806 16.0119 15.2808 16.0166C15.181 16.0212 15.0813 16.0059 14.9876 15.9715C14.8938 15.9371 14.8078 15.8844 14.7347 15.8163C14.6616 15.7482 14.6029 15.6662 14.5619 15.5752C14.5209 15.4841 14.4985 15.3857 14.496 15.2859C14.4415 14.1304 13.9441 13.0403 13.1071 12.2418C12.2701 11.4434 11.1578 10.9979 10.001 10.9979C8.84428 10.9979 7.73195 11.4434 6.89494 12.2418C6.05794 13.0403 5.56056 14.1304 5.50604 15.2859C5.49257 15.4814 5.40305 15.664 5.25666 15.7944C5.11027 15.9248 4.91864 15.9927 4.7228 15.9835C4.52697 15.9743 4.34251 15.8889 4.20891 15.7454C4.07532 15.6019 4.00321 15.4118 4.00804 15.2159C4.05947 14.1281 4.40597 13.0748 5.01039 12.169C5.61481 11.2632 6.45436 10.5389 7.43904 10.0739C6.80805 9.54754 6.35451 8.83964 6.14009 8.04643C5.92568 7.25322 5.96081 6.41322 6.24071 5.64069C6.5206 4.86815 7.03167 4.20059 7.7044 3.7288C8.37713 3.25701 9.17886 3.00391 10.0005 3.00391C10.8222 3.00391 11.6239 3.25701 12.2967 3.7288C12.9694 4.20059 13.4805 4.86815 13.7604 5.64069C14.0403 6.41322 14.0754 7.25322 13.861 8.04643C13.6466 8.83964 13.193 9.54754 12.562 10.0739ZM12.501 7.00085C12.501 6.33781 12.2376 5.70193 11.7688 5.23309C11.3 4.76425 10.6641 4.50085 10.001 4.50085C9.338 4.50085 8.70211 4.76425 8.23327 5.23309C7.76443 5.70193 7.50104 6.33781 7.50104 7.00085C7.50104 7.6639 7.76443 8.29978 8.23327 8.76862C8.70211 9.23746 9.338 9.50085 10.001 9.50085C10.6641 9.50085 11.3 9.23746 11.7688 8.76862C12.2376 8.29978 12.501 7.6639 12.501 7.00085Z" fill="currentColor"/>
  </svg>
);

export const GroupIcon = ({ width = 20, height = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5375 13.0286V13.0286C2.53745 11.963 2.85267 10.9213 3.44347 10.0344L3.55323 9.8728L3.55323 9.8728L3.55369 9.87212C3.59742 9.80656 3.62781 9.73304 3.64312 9.65574C3.65844 9.57844 3.65838 9.49888 3.64294 9.42161C3.62751 9.34433 3.59701 9.27085 3.55318 9.20536C3.50935 9.13988 3.45305 9.08366 3.38749 9.03994C3.32194 8.99621 3.24841 8.96582 3.17111 8.9505C3.09382 8.93519 3.01426 8.93525 2.93698 8.95068C2.85971 8.96612 2.78623 8.99662 2.72074 9.04045C2.65525 9.08428 2.59904 9.14058 2.55531 9.20613L2.55529 9.20616L2.44732 9.36812C2.44731 9.36813 2.4473 9.36814 2.44729 9.36815C1.72397 10.4523 1.33781 11.7263 1.3375 13.0296V13.0296V14.0016C1.3375 14.1608 1.40071 14.3134 1.51324 14.4259C1.62576 14.5384 1.77837 14.6016 1.9375 14.6016C2.09663 14.6016 2.24924 14.5384 2.36176 14.4259C2.47429 14.3134 2.5375 14.1608 2.5375 14.0016V13.0286Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
    <path d="M3.41329 9.90042L3.49648 9.95582L3.55192 9.87266C4.13736 8.99451 5.12312 8.46719 6.17972 8.46719H6.39872C6.55785 8.46719 6.71046 8.40397 6.82298 8.29145C6.9355 8.17893 6.99872 8.02632 6.99872 7.86719C6.99872 7.70806 6.9355 7.55545 6.82298 7.44292C6.71046 7.3304 6.55785 7.26719 6.39872 7.26719L6.17872 7.26719L6.17867 7.26719C5.46143 7.2675 4.75533 7.44477 4.12298 7.78326C3.49063 8.12175 2.95155 8.61102 2.55353 9.20769L2.49799 9.29095L2.58129 9.34642L3.41329 9.90042ZM10.1729 9.35772L10.0737 9.20891C10.0313 9.14137 9.97586 9.08297 9.91061 9.03711C9.84494 8.99094 9.77067 8.95843 9.6922 8.94149C9.61374 8.92455 9.53267 8.92353 9.4538 8.93849C9.37493 8.95345 9.29987 8.98408 9.23305 9.02857C9.16623 9.07306 9.10902 9.13051 9.06481 9.19751C9.02059 9.26451 8.99027 9.3397 8.97564 9.41863C8.96101 9.49756 8.96237 9.57862 8.97963 9.65702C8.99679 9.73492 9.0293 9.80861 9.07527 9.87379L9.17448 10.0226C9.63358 10.7122 9.87872 11.521 9.87872 12.3492V12.4492H9.97872H10.9787H11.0788L11.0787 12.3491C11.0783 11.2845 10.7631 10.2438 10.1729 9.35775L10.1729 9.35772Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
    <path d="M9.07531 9.86875L9.13075 9.95191L9.21394 9.89652L10.0459 9.34252L10.1292 9.28705L10.0737 9.20379C9.67568 8.60711 9.1366 8.11784 8.50425 7.77935C7.8719 7.44086 7.16581 7.2636 6.44856 7.26328H6.44852H6.22852C6.14972 7.26328 6.0717 7.2788 5.99891 7.30895C5.92611 7.33911 5.85997 7.3833 5.80425 7.43902C5.74854 7.49473 5.70434 7.56088 5.67419 7.63367C5.64404 7.70647 5.62852 7.78449 5.62852 7.86328C5.62852 7.94207 5.64403 8.0201 5.67419 8.09289C5.70434 8.16569 5.74854 8.23183 5.80425 8.28755C5.85997 8.34326 5.92611 8.38746 5.99891 8.41761C6.0717 8.44776 6.14972 8.46328 6.22852 8.46328H6.44852C7.5041 8.46328 8.48987 8.99059 9.07531 9.86875Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
    <path d="M10.5922 13.2415L10.5922 13.2415C10.0007 14.1285 9.68505 15.1708 9.685 16.237V17.271C9.685 17.4301 9.62179 17.5827 9.50926 17.6953C9.39674 17.8078 9.24413 17.871 9.085 17.871C8.92587 17.871 8.77326 17.8078 8.66074 17.6953C8.54821 17.5827 8.485 17.4301 8.485 17.271V16.237C8.48491 14.9343 8.87033 13.6607 9.59273 12.5766L10.5922 13.2415ZM10.5922 13.2415L10.7241 13.0436L10.5922 13.2415ZM9.72573 12.3766L9.59278 12.5765L10.7242 13.0435C10.8126 12.9111 10.8449 12.749 10.8138 12.5929C10.7828 12.4368 10.6909 12.2993 10.5586 12.2109C10.4262 12.1224 10.2641 12.0901 10.1079 12.1212C9.95175 12.1522 9.81432 12.2441 9.72586 12.3764L9.72573 12.3766ZM7.07277 6.98634C6.81192 7.09439 6.53234 7.15 6.25 7.15C5.67978 7.15 5.13292 6.92348 4.72972 6.52028C4.32652 6.11708 4.1 5.57022 4.1 5C4.1 4.42978 4.32652 3.88292 4.72972 3.47972C5.13292 3.07652 5.67978 2.85 6.25 2.85C6.53234 2.85 6.81192 2.90561 7.07277 3.01366C7.33362 3.12171 7.57063 3.28007 7.77028 3.47972C7.96993 3.67937 8.12829 3.91638 8.23634 4.17723C8.34439 4.43808 8.4 4.71766 8.4 5C8.4 5.28234 8.34439 5.56192 8.23634 5.82277C8.12829 6.08362 7.96993 6.32063 7.77028 6.52028C7.57063 6.71993 7.33362 6.87829 7.07277 6.98634ZM6.25 8.35C7.13848 8.35 7.99056 7.99705 8.61881 7.36881C9.24705 6.74056 9.6 5.88848 9.6 5C9.6 4.11152 9.24705 3.25944 8.61881 2.63119C7.99056 2.00295 7.13848 1.65 6.25 1.65C5.36152 1.65 4.50944 2.00295 3.88119 2.63119C3.25295 3.25944 2.9 4.11152 2.9 5C2.9 5.88848 3.25295 6.74056 3.88119 7.36881C4.50944 7.99705 5.36152 8.35 6.25 8.35Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
    <path d="M13.4196 11.6H13.4196H13.6436C13.8027 11.6 13.9553 11.5368 14.0679 11.4243C14.1804 11.3117 14.2436 11.1591 14.2436 11C14.2436 10.8409 14.1804 10.6883 14.0679 10.5757C13.9553 10.4632 13.8027 10.4 13.6436 10.4H13.4196H13.4196C12.6886 10.4001 11.969 10.5806 11.3245 10.9256C10.6801 11.2705 10.1308 11.7693 9.72538 12.3775L9.66997 12.4607L9.75305 12.5162L10.5841 13.0712L10.6673 13.1268L10.7228 13.0434C11.0187 12.5994 11.4197 12.2353 11.8901 11.9835C12.3606 11.7316 12.886 11.5999 13.4196 11.6ZM18.6306 16.237V16.237C18.6304 14.9341 18.2446 13.6605 17.5218 12.5765L17.3889 12.3766L17.3887 12.3764C17.3003 12.2441 17.1628 12.1522 17.0067 12.1212C16.8505 12.0901 16.6884 12.1224 16.556 12.2109C16.4236 12.2993 16.3318 12.4368 16.3008 12.5929C16.2697 12.7491 16.302 12.9112 16.3905 13.0436L16.3906 13.0438L16.5234 13.2415C16.5234 13.2415 16.5235 13.2416 16.5235 13.2416C17.1149 14.1286 17.4305 15.1709 17.4306 16.237V17.271C17.4306 17.4301 17.4938 17.5827 17.6063 17.6953C17.7189 17.8078 17.8715 17.871 18.0306 17.871C18.1897 17.871 18.3423 17.8078 18.4549 17.6953C18.5674 17.5827 18.6306 17.4301 18.6306 17.271V16.237Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
    <path d="M13.6957 11.6H13.6957C14.2293 11.5999 14.7547 11.7316 15.2252 11.9835C15.6956 12.2353 16.0966 12.5994 16.3925 13.0434L16.448 13.1267L16.5312 13.0712L17.3632 12.5162L17.4464 12.4607L17.3909 12.3775C16.9854 11.7691 16.436 11.2703 15.7913 10.9253C15.1467 10.5803 14.4268 10.3999 13.6957 10.4C13.6957 10.4 13.6957 10.4 13.6957 10.4H13.4707C13.3116 10.4 13.159 10.4632 13.0464 10.5757C12.9339 10.6883 12.8707 10.8409 12.8707 11C12.8707 11.1591 12.9339 11.3117 13.0464 11.4243C13.159 11.5368 13.3116 11.6 13.4707 11.6H13.6957Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
    <path d="M14.3228 10.2363C14.0619 10.3444 13.7823 10.4 13.5 10.4C12.9298 10.4 12.3829 10.1735 11.9797 9.77028C11.5765 9.36708 11.35 8.82022 11.35 8.25C11.35 7.67978 11.5765 7.13292 11.9797 6.72972C12.3829 6.32652 12.9298 6.1 13.5 6.1C13.7823 6.1 14.0619 6.15561 14.3228 6.26366C14.5836 6.37171 14.8206 6.53007 15.0203 6.72972C15.2199 6.92937 15.3783 7.16638 15.4863 7.42723C15.5944 7.68808 15.65 7.96766 15.65 8.25C15.65 8.53234 15.5944 8.81192 15.4863 9.07277C15.3783 9.33362 15.2199 9.57063 15.0203 9.77028C14.8206 9.96993 14.5836 10.1283 14.3228 10.2363ZM13.5 11.6C14.3885 11.6 15.2406 11.2471 15.8688 10.6188C16.4971 9.99056 16.85 9.13848 16.85 8.25C16.85 7.36152 16.4971 6.50944 15.8688 5.88119C15.2406 5.25295 14.3885 4.9 13.5 4.9C12.6115 4.9 11.7594 5.25295 11.1312 5.88119C10.5029 6.50944 10.15 7.36152 10.15 8.25C10.15 9.13848 10.5029 9.99056 11.1312 10.6188C11.7594 11.2471 12.6115 11.6 13.5 11.6Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"/>
  </svg>
)

export const SearchIcon = ({ width = 23, height = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.4985 9.14225C15.4238 12.6188 12.6196 15.4233 9.14322 15.4985C9.09561 15.4995 9.04787 15.5 9 15.5C5.41015 15.5 2.5 12.5899 2.5 9C2.5 5.41015 5.41015 2.5 9 2.5C12.5899 2.5 15.5 5.41015 15.5 9C15.5 9.04754 15.4995 9.09496 15.4985 9.14225ZM15.676 14.2618C16.8183 12.8145 17.5 10.9869 17.5 9C17.5 4.30558 13.6944 0.5 9 0.5C4.30558 0.5 0.5 4.30558 0.5 9C0.5 13.6944 4.30558 17.5 9 17.5C10.7604 17.5 12.3958 16.9648 13.7524 16.0483C13.9269 15.9304 14.0968 15.8062 14.2618 15.676L20.7929 22.2071C21.1834 22.5976 21.8166 22.5976 22.2071 22.2071C22.5976 21.8166 22.5976 21.1834 22.2071 20.7929L15.676 14.2618Z" fill="black"/>
  </svg>

);

export const ProfileIcon = ({ width = 21, height = 25 }) => (
  <svg width={width} height={height} viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4728 2.46094C8.3834 2.46094 6.84947 4.05484 7.01159 6.31105C7.18032 8.65609 8.91191 10.3047 10.4728 10.3047C12.0327 10.3047 13.7622 8.65662 13.9341 6.30974C14.0959 4.09942 12.5559 2.46094 10.4728 2.46094ZM5.01674 6.4545C4.77357 3.0711 7.1916 0.460938 10.4728 0.460938C13.7603 0.460938 16.1723 3.12906 15.9288 6.45582L15.9288 6.45589C15.7035 9.53024 13.3715 12.3047 10.4728 12.3047C7.57507 12.3047 5.23815 9.5318 5.01675 6.45458L5.01674 6.4545ZM0.530868 21.7632C1.40123 16.6156 6.08664 14 10.4996 14C14.9127 14 19.598 16.6156 20.4689 21.7632L20.4689 21.7632C20.5506 22.2463 20.4739 22.7729 20.1934 23.21C19.8966 23.6722 19.385 24 18.7493 24H2.24996C1.61415 24 1.10272 23.6719 0.806233 23.2098C0.525848 22.7728 0.449183 22.2463 0.53086 21.7633M2.51988 22H18.4799C17.7755 18.1484 14.2115 16 10.4996 16C6.78768 16 3.22377 18.1484 2.51988 22Z" fill="black"/>
  </svg>

);

export const CalendarIcon = ({ width = 18, height = 17 }) => (
  <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_30054_168387)">
      <path d="M14.25 1.41667H13.5V0.708333C13.5 0.520472 13.421 0.340304 13.2803 0.207466C13.1397 0.0746278 12.9489 0 12.75 0C12.5511 0 12.3603 0.0746278 12.2197 0.207466C12.079 0.340304 12 0.520472 12 0.708333V1.41667H6V0.708333C6 0.520472 5.92098 0.340304 5.78033 0.207466C5.63968 0.0746278 5.44891 0 5.25 0C5.05109 0 4.86032 0.0746278 4.71967 0.207466C4.57902 0.340304 4.5 0.520472 4.5 0.708333V1.41667H3.75C2.7558 1.41779 1.80267 1.79129 1.09966 2.45524C0.396661 3.11919 0.00119089 4.01937 0 4.95833L0 13.4583C0.00119089 14.3973 0.396661 15.2975 1.09966 15.9614C1.80267 16.6254 2.7558 16.9989 3.75 17H14.25C15.2442 16.9989 16.1973 16.6254 16.9003 15.9614C17.6033 15.2975 17.9988 14.3973 18 13.4583V4.95833C17.9988 4.01937 17.6033 3.11919 16.9003 2.45524C16.1973 1.79129 15.2442 1.41779 14.25 1.41667ZM1.5 4.95833C1.5 4.39475 1.73705 3.85425 2.15901 3.45573C2.58097 3.05722 3.15326 2.83333 3.75 2.83333H14.25C14.8467 2.83333 15.419 3.05722 15.841 3.45573C16.2629 3.85425 16.5 4.39475 16.5 4.95833V5.66667H1.5V4.95833ZM14.25 15.5833H3.75C3.15326 15.5833 2.58097 15.3595 2.15901 14.9609C1.73705 14.5624 1.5 14.0219 1.5 13.4583V7.08333H16.5V13.4583C16.5 14.0219 16.2629 14.5624 15.841 14.9609C15.419 15.3595 14.8467 15.5833 14.25 15.5833Z" fill="currentColor"/>
      <path d="M9 11.6875C9.62132 11.6875 10.125 11.2118 10.125 10.625C10.125 10.0382 9.62132 9.5625 9 9.5625C8.37868 9.5625 7.875 10.0382 7.875 10.625C7.875 11.2118 8.37868 11.6875 9 11.6875Z" fill="currentColor"/>
      <path d="M5.25 11.6875C5.87132 11.6875 6.375 11.2118 6.375 10.625C6.375 10.0382 5.87132 9.5625 5.25 9.5625C4.62868 9.5625 4.125 10.0382 4.125 10.625C4.125 11.2118 4.62868 11.6875 5.25 11.6875Z" fill="currentColor"/>
      <path d="M12.75 11.6875C13.3713 11.6875 13.875 11.2118 13.875 10.625C13.875 10.0382 13.3713 9.5625 12.75 9.5625C12.1287 9.5625 11.625 10.0382 11.625 10.625C11.625 11.2118 12.1287 11.6875 12.75 11.6875Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_30054_168387">
        <rect width="18" height="17" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export const FlagIcon = ({ width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
      d="M0.00710456 1.81469L0 1.80075C2.01869 0.771744 3.63997 0.206681 5.16786 0.0473884C6.70714 -0.113092 8.09754 0.143835 9.64102 0.669625C10.2682 0.883283 10.8489 1.09969 11.3823 1.29846C11.659 1.40155 11.9229 1.4999 12.174 1.59067C12.9239 1.86176 13.5891 2.07687 14.2629 2.208C15.5797 2.46429 16.9953 2.41305 19.1402 1.68124L20 1.38792V12.9286L19.5955 13.0936C18.3397 13.6057 16.8099 14.1639 14.9882 14.241C13.1542 14.3187 11.068 13.9079 8.68432 12.5573C7.21545 11.725 5.63303 11.6925 4.19219 11.9526C3.07646 12.154 2.11162 12.5137 1.35675 12.7951C1.34008 12.8014 1.32336 12.8076 1.3069 12.8137L1.3069 20.0033H0.00710534L0.00710456 1.81469ZM1.3069 2.60575C2.90567 1.84731 4.16313 1.45898 5.30264 1.34018C6.59575 1.20536 7.78978 1.41214 9.2219 1.89999C9.83414 2.10855 10.3795 2.31189 10.9009 2.50628C11.1827 2.61134 11.4575 2.71379 11.7321 2.81304C12.4999 3.09062 13.2405 3.3332 14.0146 3.48386C15.3911 3.75177 16.8202 3.71866 18.7002 3.18161V12.052C17.5824 12.4899 16.3492 12.8825 14.9332 12.9424C13.3565 13.0091 11.5099 12.6643 9.32509 11.4264C7.49829 10.3913 5.57458 10.3822 3.96129 10.6735C2.95479 10.8551 2.0253 11.1652 1.3069 11.4265L1.3069 2.60575Z"
      fill="currentColor" fillOpacity="0.8"/>
  </svg>
);

export const LinkArrowIcon = ({ width = 18, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.07692 14L12.4615 6.61538V13.2308H14V4H4.76923V5.53846H11.3846L4 12.9231L5.07692 14Z" fill="currentColor"/>
  </svg>
);

